<template>

<div>

  <div class="screen jobpage">

      <h2 class="screen__title">Nye kunder venter på tilbud</h2>
      <AwaitingJobs :viewAllBtn="false" :showOfferBtn="true" />

      <br>

      <h2 class="screen__title">Nyeste opgaver <span>(Seneste 24 timer)</span></h2>
      <AllJobs />

  </div>

  <Paywall />    
      
</div>

</template>


<script>

import AwaitingJobs from "@/components/AwaitingJobs";
import AllJobs from "@/components/AllJobs";
import Paywall from "@/components/Paywall";

export default {
  components: {
    AwaitingJobs,
    AllJobs,
    Paywall
  },

  data() {
      return {
      }
  },
};
</script>


<style scoped lang="scss">


.jobpage {


}


</style>