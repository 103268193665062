<template>

<div class="paywall">        

    <div class="paywall__gradient"></div>
    <div class="paywall__paystop">
        <hr>
        <button class="btn btn--green btn--center btn--icon" @click="trigger_contact_modal">
            <Svgicon icon="icons/lock" />
            Ansøg om fuld adgang
        </button>
    </div>
<!-- 
    <div class="corset">
        <h1 class="text_center">Få fuld adgang til vores platform og fordele</h1>
        <p class="text_center">Vi laver altid aftaler, der passer til dit firmas kapacitet, kompetencer og ønsker. <br>
        Herunder kan du danne dig et indtryk af vores prisniveau.</p>
    </div>

    <div class="pricing">
        <div class="pricing__packages">
            <div class="pricing__package highlighted">

                <h4 class="pricing__title">Basisoptagelse</h4>
                <p class="pricing__subtitle"></p>

                <div class="pricing__price">
                    <span>Fra 12.900 kr.</span>
                    <span></span>   

                    <div class="pricing__splash">Vi skræddersyer aftalen til dit firma</div>
                </div>

                <a href="#" class="pricing__cta scroll_to_buildersignup btn btn--green btn--rounded btn--large" @click.prevent="trigger_contact_modal">Tilmeld nu</a>

                <ul class="pricing__checkmarklist">
                    <li>Standardprofil på 3byggetilbud.dk </li>
                    <li>Byggeopgaver fra private</li>
                    <li>Personlig konsulent samt leverandøroverblik</li>
                    <li>Gratis Entreprisegaranti</li>
                    <li>Fordelsprogram</li>
                </ul>

                <hr>

                <ul class="pricing__pluslist">
                    <li>Verificerede bygge- og renoveringsopgaver</li>
                    <li>Skræddersyet aftale der matcher netop din virksomhed</li>
                    <li>Vi tager ingen procenter af hverken tildelte eller vundne opgaver</li>
                    <li>Ingen binding</li>
                </ul>
            </div>

            <div class="pricing__package ">

                <h4 class="pricing__title">Basisoptagelse + Plusprofil</h4>
                <p class="pricing__subtitle"></p>

                <div class="pricing__price">
                    <span>Fra 22.900 kr.</span>
                    <span></span>
                </div>

                <a href="#" class="pricing__cta scroll_to_buildersignup btn btn--rounded btn--large" @click.prevent="trigger_contact_modal">Tilmeld nu</a>

                <ul class="pricing__checkmarklist">
                    <li>Plusprofil på 3byggetilbud.dk </li>
                    <li>Udvidet og SEO-optimeret profilside om firmaet</li>
                    <li>Journalistisk samtale og skriv</li>
                    <li>Mulighed for fotograf</li>
                    <li>Byggeopgaver fra private</li>
                    <li>Personlig konsulent samt leverandøroverblik</li>
                    <li>Gratis Entreprisegaranti</li>
                    <li>Fordelsprogram</li>
                </ul>

                <hr>

                <ul class="pricing__pluslist">
                    <li>Verificerede bygge- og renoveringsopgaver</li>
                    <li>Skræddersyet aftale der matcher netop din virksomhed</li>
                    <li>Vi tager ingen procenter af hverken tildelte eller vundne opgaver</li>
                    <li>Ingen binding</li>
                </ul>
            </div>
        </div>
    </div> -->

</div>

</template>

<script>

export default {
    props: {},
    data() {
        return {}
    },

	methods: {
		trigger_contact_modal() {
            this.$store.dispatch('contact_toggle', true)
        }
	}
}
</script>


<style scoped lang="scss">

.paywall {
    background: white;
    position: relative;

    &__gradient {
        position: absolute;
        top: -350px;
        left: 0;
        width: 100%;
        height: 400px;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.85) 60%, rgba(255,255,255,1) 100%);
    }

    &__paystop {

        min-height: 200px;
        background: white;
        margin-top: -50px;

        hr {
            position: relative;
            width: 100%;
            border: none;
            border-bottom: 2px solid #eee;
            bottom: -40px;
            max-width: $large;
            width: 90%;
            transform: translate(-50%, -50%);
            left: 50%;
        }

        button {
            border-radius: 100px;
            outline: white solid 10px;
            display: block;
            margin: 0 auto
        }
    }

    h1 {
        margin-bottom: 1rem;
    }

}




$bg_color: #D4DCF2;
.pricing {
	padding: 5rem 15px;
    padding-top: 3rem;

    margin-bottom: -4rem;
    padding-bottom: 7rem;


	* {
		color: $color1;
	}

	hr {
		border: none;
		border-bottom: 1px solid $color1;
		margin: 25px 0;
	}

	&__packages {
		display: flex;
    	flex-wrap: wrap;
		justify-content: center;
		align-items: flex-start;
		gap: 3rem;
	}

	&__package {
		border-radius: 10px;
		border: 2px solid $bg_color;
		padding: 4rem 2rem;
		max-width: 360px;

		&.highlighted {
			background: $bg_color;
		}
	}

	&__subtitle {
		font-size: 0.9rem;
	}

	&__price {
		position: relative;
		margin-bottom: 1rem;
		span:first-of-type {
			@extend .h2 !optional;
			display: block;
		}

		span:last-of-type {
			font-size: 0.9rem;
		}

	}

	&__splash {
		position: absolute;
		left: -230px;
		width: 180px;
		text-align: center;
		&:after {
			content: '';
			background-image: url('~@/assets/img/toon_arrow_reverse.svg');
			width: 50px;
			height: 50px;
			background-size: contain;
			background-position: center;
			position: absolute;
			top: -51px;
			right: -20px;
			background-repeat: no-repeat;
			transform: rotate(196deg);
		}

		@media( max-width: 1165px ) {
			display: none;
		}
	}

	&__cta {
		color: white;
		display: inline-block;
		margin-bottom: 1.5rem;
	}

	ul li {
		line-height: 1.3;
	}


	&__checkmarklist {
		margin: 0;
		li {
			list-style: none;
			position: relative;
			margin-left: 0px;
			padding: 0.7rem;
			padding-left: 35px;
			&:before {
				content: '';
				position: absolute;
				background-image: url('~@/assets/img/check_blue.svg');
				font-style: inherit;
				width: 20px;
				height: 20px;
				top: 22px;
				left: 0px;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				transform: translateY(-50%);
			}
		}
	}

	&__pluslist {
		margin: 0;
		li {
			list-style: none;
			position: relative;
			margin-left: 0px;
			padding: 0.5rem 0;
			padding-left: 25px;
			font-size: 0.9rem;
			&:before {
				content: '';
				position: absolute;
				background-image: url('~@/assets/img/plus_3byg.svg');
				font-style: inherit;
				width: 13px;
				height: 13px;
				top: 15px;
				left: 0px;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				transform: translateY(-50%);
			}
		}
	}
}

  
</style>