<template>

<div class="alljobs box">        

    <table cellspacing="0" cellpadding="0" v-if="jobs">
        <thead>
            <tr>
                <th>Ref. nr.</th>
                <th>Opgave</th>
                <th>Område</th>
                <th>Modtaget</th>
                <th></th>
            </tr>
        </thead>

        <tbody>
            <tr v-for="(job, index) in jobs" :key="index" class="jobs__row">
                <td>{{job.reference}}</td>
                <td><router-link :to="'/opgave/' + job.reference +'/' + this.$route.hash">{{dotdotdot(job.title)}}</router-link></td>
                <td>{{job.postalCode}} {{job.city}}</td>
                <td>{{timeSince(job.timestamp)}}</td>
                <td><router-link :to="'/opgave/' + job.reference +'/' + this.$route.hash" class="btn btn--border btn--full btn--small" >Åbn opgave</router-link></td>
            </tr>
        </tbody>
    </table>

</div>

</template>

<script>

export default {
    props: {},
    data() {
        return {

        }
    },

    computed: {
        jobs() {
            return this.$store.getters.all_jobs;
        }
    },

    methods: {
        trigger_modal() {
            this.$store.dispatch('limited_access_toggle', true)
        },

        dotdotdot(title) {
            if( title.length > 49) {
                return title + '...'
            }
            return title;
        }
    },
    

}
</script>


<style scoped lang="scss">

table {
    width: 100%;
    border: none;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid darken($color6, 5%);

    thead {
        background: $color16;
    }

    th, td {
        text-align: left;
        width: auto;
        padding: 15px 15px;

        &:last-child {
            width: 180px;
        }
    }

    tbody tr {
        &:nth-child(even) {
            background: $color6;
        }

        td {
            border-top: 1px solid darken($color6, 5%);
        }
    }


    @include large {
        th, td {
            &:nth-child(1) {
                display: none;
            }

            &:last-child {
                width: 140px;
            }
            
        }
    }

    @include medium {
        th, td {
            &:nth-child(3) {
                display: none;
            }
        }
    }
    @include small {
        th, td {
            &:nth-child(4) {
                display: none;
            }
        }
    }
    
}


  
</style>