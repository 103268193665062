<template>

    <div class="awaitingjobs box" :class="{'awaitingjobs--viewallbtn' : viewAllBtn } ">

        <div class="awaitingjobs__banner">Nyeste</div>

        <h3 class="awaitingjobs__title" v-if="title">{{title}}</h3>

        <div class="jobs">
            <div class="jobs__scrollcontainer">

                <div v-for="(job, index) in awaitingJobs" :key="index" class="jobs__row">
                    <JobItem :data="job" :showOfferBtn="showOfferBtn" />
                </div>

            </div>

            <div class="jobs__row" v-if="viewAllBtn">
                <a @click="goto_opgaver" class="viewall">

                    <div class="viewall__cover">
                        <Svgicon icon="icons/chevron_right" />
                    </div>
                    <p class="viewall__title">Se alle opgaver</p>

                </a>
            </div>

            
        </div>

    </div>

</template>

<script>
import JobItem from "@/components/JobItem";

export default {
    components: {JobItem},
    props: {
        viewAllBtn: {
            default: true,
        },
        showOfferBtn: {
            default: false,
        },
        title: String,
    },
    data() {
        return {
        }
    },

    computed: {
        awaitingJobs() {
            return this.$store.getters.latest_jobs;
        }
    },

    methods: {

        goto_opgaver() {
            this.$router.push('/opgaver/'+ this.$route.hash)
        }

    },
    

}
</script>


<style scoped lang="scss">

.awaitingjobs {

    position: relative;
    overflow: hidden;

    &__title {
        z-index: 2;
        position: relative;
    }
    
    &__banner {
        position: absolute;
        top: 18px;
        right: -35px;
        padding: 3px 40px;
        color: white;
        background: $color9;
        transform: rotate(45deg);
        font-weight: 500;
        font-size: 0.8rem;
        z-index: 1;
    }

}

.jobs {
    margin-top: 1rem;
    
    display: flex;
    gap: 1rem;

    &__scrollcontainer {
        width: 100%;
        overflow: scroll;
        padding-top: 10px;
        display: flex;
        gap: 1rem;
        padding-bottom: 1rem;
        justify-content: space-between;
    }
    
}

.awaitingjobs--viewallbtn {
    .jobs__scrollcontainer {
        width: calc(100% - 150px);
    }


    .jobs:after {
        right: 12rem;
        @include extralarge_up {
            right: 15rem;
        }

        @include mobile {
            right: 11rem;
        }

    
    }

}

@media( max-width: 1200px ) and ( min-width: 740px ) {
    .jobs__row:last-child {
        display: none;
    }
}


.viewall {
    position: relative;
    padding-top: 10px;
    display: block;

    .jobs__scrollcontainer {
        &:after {
            content: '';
            position: absolute;
            right: 2rem;
            top: 0;
            width: 25px;
            background: rgb(255,255,255);
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.85) 60%, rgba(255,255,255,1) 100%);
            height: 100%;

            @include small {
                right: 0rem;
            }
        }
    }

  
    &__cover {
        width: 150px;
        height: 130px;
        border-radius: $radius;
        margin-bottom: 0.6rem;
        border: 1px dashed $color9;
        position: relative;

        @include extralarge_up {
            width: 200px;
            height: 170px;
        }
    }

    .svgicon {
        width: 40px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__title {
        font-weight: 600;
        text-align: center;
        font-size: 0.9rem;
        margin-bottom: 1rem;
    }
}
  
</style>