<template>
<div class="jobitem" @click="trigger_modal(data.reference)" v-if="data">

    <span class="jobitem__banner">{{timeSince(data.timestamp)}}</span>
    <img class="jobitem__cover" :src="image(data)" alt="">
    <p class="jobitem__title">{{title}}</p>
    <button v-if="showOfferBtn" class="btn btn--border btn--small">Åbn opgave</button>

</div>
</template>

<script>

export default {
    props: {
        data: {
            default: null,
            type: Object,
        },
        showOfferBtn: Boolean,
    },
    data() {
        return {
            
        }
    },

    computed: {

        title() {
            if( this.data.title.length > 25 ) {
                return this.data.title.substring(0, 25) + '...'
            } else {
                return this.data.title;
            }
        }
    },

    methods: {

        image(job) {

            // if( job.energyLabelPictureUrl !== '') {
            //     return job.energyLabelPictureUrl
            // } else if( job.gmapsStreetViewImageUrl !== '') {
            //     return job.gmapsStreetViewImageUrl
            // }
            if( job.gmapsStreetViewImageUrl !== '') {
                return job.gmapsStreetViewImageUrl
            }
            return require( "@/assets/img/placeholder_img.png") 
            
        },
        trigger_modal(reference) {
            this.$router.push('/opgave/' + reference + '/' + this.$route.hash)
            // if( !this.showOfferBtn ) { // If preview link directly to subpage.
            //     this.$router.push('/opgave/' + reference + '/' + this.$route.hash)
            // } else { // else gate!
            //     this.$store.dispatch('limited_access_toggle', true)
            // }
        }

    }

}
</script>

<style scoped lang="scss">

.jobitem {

    width: 150px;
    display: inline-block;
    position: relative;
    cursor: pointer;

    &__banner {
        position: absolute;
        top: 0px;
        margin: 0 auto;
        background: $color9;
        padding: 3px 10px;
        border-radius: 20px;
        font-size: 0.8rem;
        transform: translate(-50%, -50%);
        left: 50%;
        width: 80%;
        text-align: center;
        color: white;
    }

    &__cover {
        width: 150px;
        height: 130px;
        object-fit: cover;
        border-radius: $radius;
        margin-bottom: 0.6rem;
        border: 1px solid $color9;
        background: #eee;
    }

    &__title {
        font-weight: 600;
        text-align: center;
        font-size: 0.9rem;
        height: 50px;
    }

    button {
        width: 90%;
        margin: 0 auto;
        text-align: center;
        display: block;
    }

    @include extralarge_up {
        width: 200px;

        &__cover {
            width: 200px;
            height: 170px;
        }
        
    }
}

</style>